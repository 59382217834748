import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { mapOktaLanguageCode } from '@shure/cloud/shared/services/organizations-store-service';
import { DEFAULT_AVAILABLE_LANGUAGES, LanguagesService } from '@shure/shared/angular/utils/i18n';

import { CommonDialogsService } from '../dialogs';
import { CloseTextOption, SnackbarService } from '../html-snackbar';

@Component({
	selector: 'sh-language-selector',
	templateUrl: 'language-selector.component.html',
	styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
	@Output() public languageChanged: EventEmitter<string> = new EventEmitter();
	@Input() public preferredCultureCode = '';
	@Input() public isDisabled = false;
	public languages: { id: string; label: string }[] = [];
	public languageSelectorForm: FormGroup = new FormGroup({
		preferredLanguage: new FormControl('', Validators.required)
	});
	private languageCultureCodes: string[] = [];
	protected destroy$: Subject<void> = new Subject<void>();

	constructor(
		private languageService: LanguagesService,
		public dialogService: CommonDialogsService,
		private translocoService: TranslocoService,
		private snackBarService: SnackbarService
	) {}

	public ngOnInit(): void {
		this.preferredCultureCode = this.languageService.getPreferredCultureCode();
		if (this.isDisabled) {
			this.languageSelectorForm.get('preferredLanguage')?.disable();
		}
		this.languages = DEFAULT_AVAILABLE_LANGUAGES;
		this.languageCultureCodes = Object.values(mapOktaLanguageCode);
		this.updatePreferredLanguageForm();
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public get preferredLanguage(): FormControl {
		return <FormControl>this.languageSelectorForm.get('preferredLanguage');
	}

	public setLocale(cultureCode: string): void {
		const dialogRef = this.dialogService.openConfirmDialog({
			title: this.translocoService.translate('cloud.shared.labels.preferred-language-was-changed'),
			body: this.translocoService.translate(
				'cloud.shared.labels.reload-the-application-to-use-the-new-language-setting'
			),
			confirmLabel: this.translocoService.translate('cloud.shared.labels.reload'),
			cancelLabel: this.translocoService.translate('cloud.shared.labels.cancel')
		});

		dialogRef.pipe(take(1), takeUntil(this.destroy$)).subscribe((result) => {
			result ? this.setLocaleAndEmit(cultureCode) : this.updatePreferredLanguageForm();
		});
	}

	public setLocaleAndEmit(cultureCode: string): void {
		if (this.languageCultureCodes.includes(cultureCode)) {
			this.languageChanged.emit(cultureCode);
		} else {
			this.snackBarService.open(
				this.translocoService.translate('cloud.shared.notification-preferences.unsupported-language-message'),
				CloseTextOption.Ok
			);
			this.updatePreferredLanguageForm();
		}
	}

	private updatePreferredLanguageForm(): void {
		const cultureCode: string = !this.languageCultureCodes.includes(this.preferredCultureCode)
			? LanguagesService.systemDefaultCultureCode
			: this.preferredCultureCode;
		this.preferredLanguage.setValue(cultureCode);
		this.languageSelectorForm.markAsPristine();
	}
}
